/* Contact Section */
#contact {
  padding: 100px 0 60px;
  background: linear-gradient(to top, #f3b311 1%, #ffffff 80%);
  color: rgba(0, 0, 0, 0.75);
  text-align: center; /* Center text */
  /* background: url(../../../public/img/paperplane.jpg); */
  background-repeat: repeat-x;
  background-size: cover;
  background-position: center 50%; 
  opacity: 0.85;
}

.form-wrapper {
  width: 100%; /* Full width */
  max-width: 800px; /* Adjust as necessary */
  margin: 0 auto; /* Center the wrapper */
  padding: 20px; /* Optional padding */
  background-color: transparent; /* Subtle grey background*/
  border-radius: 8px; /* Rounded corners for the wrapper */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Optional shadow for depth */
}

.section-title {
  margin-bottom: 40px;
}

.section-title h2 {
  color: #000000;
}

.footer-content {
  text-align: center; /* Center the copyright text */
  margin-top: 20px; /* Space above copyright */
}

.social {
  margin-top: 20px; /* Space above social icons */
}

.social-icons {
  display: flex;
  justify-content: center; /* Center social icons */
}

.social ul {
  display: flex;
  list-style: none;
  padding: 0;
}

.social li {
  margin: 0 10px;
}

.social i {
  font-size: 22px;
  color: #000; /* Change to the desired color */
}

.social i:hover {
  color: #d9a216; /* Hover color */
}
