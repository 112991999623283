/* Navigation */
#menu {
  padding: 15px;
  transition: all 0.8s;
}

#menu.navbar-default {
  /* background-color: #fff; */
  background-color: transparent;
  border-color: rgba(231, 231, 231, 0);
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.15); */
}
#menu.scrolled {
  background-color: #000000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  opacity: 0.8;
  padding-bottom: 20px;
}

#menu.navbar-right {
  margin-top: 12% !important;
}
#menu button.navbar-brand {
  font-family: "Raleway", sans-serif;
  font-size: 24px;
  font-weight: 700;
  color: #333;
  text-transform: uppercase;
  padding-top: 20px;
}
#menu.navbar-default .navbar-nav > li > button {
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  padding: 8px 2px;
  border-radius: 0;
  border: none;
  background: none;
  margin: 10px 10px 0;
}

#menu.navbar-default .navbar-nav > li > button:after {
  display: block;
  position: absolute;
  bottom: -1px;
  width: 0;
  height: 2px;
  background: linear-gradient(to right, #FFD700 0%, #FFAA00 100%);
  content: "";
  transition: width 0.2s;
}
#menu.navbar-default .navbar-nav > li > button:hover:after {
  width: 80%; /* Only half of the text width */
}

/* Add background color for smaller screens (max-width: 768px) */
@media screen and (max-width: 768px) {
  #menu.navbar-default .navbar-nav > li  {
      background-color: #1d1c1c; /* Golden background */
      color: #ffffff; /* Dark text for contrast */
      opacity: 0.8;
  }
  #menu.navbar-default .navbar-nav > li > button:hover:after {
    width: 25%; /* Only half of the text width */
  }
}


.navbar-default .navbar-nav > .active > button,
.navbar-default .navbar-nav > .active > button:hover,
.navbar-default .navbar-nav > .active > button:focus {
  background-color: transparent;
}
.navbar-default .navbar-nav > .active > button:after,
.navbar-default .navbar-nav > .active > button:hover:after,
.navbar-default .navbar-nav > .active > button:focus:after {
  display: block !important;
  position: absolute !important;
  left: 0 !important;
  bottom: -1px !important;
  width: 100% !important;
  height: 2px !important;
  background: linear-gradient(to right,#FFD700 0%, #FFAA00 100%) !important;
  content: "" !important;
  transition: width 0.2s !important;
}
.navbar-toggle {
  border: none;
  margin-top: 20px;
}
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background-color: #fff;
  border-color: #608dfd;
}
.navbar-default .navbar-toggle:hover > .icon-bar {
  background-color: #608dfd;
}
.section-title {
  margin-bottom: 70px;
}
.section-title h2 {
  position: relative;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
.section-title h2::after {
  position: absolute;
  content: "";
  background: linear-gradient(to right,#FFD700 0%, #FFAA00 100%);
  height: 4px;
  width: 60px;
  bottom: 0;
  margin-left: -30px;
  left: 50%;
}
.section-title p {
  font-size: 18px;
}
.btn-custom {
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  color: #fff;
  background-color: #5ca9fb;
  background-image: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
  padding: 14px 34px;
  letter-spacing: 1px;
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  border-radius: 25px;
  transition: all 0.5s linear;
  border: 0;
}
.btn-custom:hover,
.btn-custom:focus,
.btn-custom.focus,
.btn-custom:active,
.btn-custom.active {
  color: #fff;
  background-image: none;
  background-color: #6372ff;
}
.btn:active,
.btn.active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
button:focus,
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none;
  outline-offset: none;
}

