/* About Section */
#about {
  background: linear-gradient(to bottom, #f3b311 30%, #ffffff 100%);
  color: rgba(0, 0, 0, 0.75);
  padding: 100px 0;
  padding: 60px 20px;
  opacity: 0.85;
}

#about h3 {
  font-size: 22px;
  margin: 0 0 20px;
}
#about h2 {
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
#about h2::after {
  position: absolute;
  content: "";
  background: linear-gradient(to right,#FFD700 0%, #FFAA00 100%);
  height: 4px;
  width: 60px;
  bottom: 0;
  left: 0;
  
}
#about .about-text li {
  margin-bottom: 6px;
  margin-left: 6px;
  list-style: none;
  padding: 0;
}
#about .about-text li:before {
  content: "\f00c";
  font-family: "FontAwesome";
  color: #d9a216;
  font-size: 11px;
  font-weight: 300;
  padding-right: 8px;
}
#about img {
  width: 520px;
  margin-top: 10px;
  background: #fff;
  border-right: 0;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.06);
}
#about p {
  line-height: 24px;
  margin: 30px 0;
}

@media (max-width: 768px) {
  #about img {
    margin: 50px 0;
  }
}

