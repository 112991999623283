/* Services Section  #c99308 0%, #060606 100% */
#services {
  padding: 100px 0;
  background: linear-gradient(to bottom, #ffffff 30%, #f3b311 100%);
  color: #0f0e0e;
  opacity: 0.85;
}


#services .service-desc {
  margin: 10px 10px 20px;
}

#services i.fa  {
  font-size: 40px;
  color: #333;
  animation: bounce 1.5s ease 1;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px); /* Move the icon up */
  }
}

#services i.fa:hover {
  animation: none; /* Disable bounce on hover */
  transform: scale(1.2); /* Scale up the icon */
  color: #f0f0f0; /* Icon color on hover */
}

#services h2 {
  color: #000000;
}
#services .section-title h2::after {
  position: absolute;
  content: "";
  height: 4px;
  width: 60px;
  bottom: 0;
  margin-left: -30px;
  left: 50%;
}
#services i.fa {
  font-size: 42px;
  width: 120px;
  height: 120px;
  padding: 40px 0;
  background: linear-gradient(to right,#b74717 0%, #FFAA00 100%);
  border-radius: 50%;
  color: #000000;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.05);
}
#services h3 {
  font-weight: 500;
  padding: 5px 0;
  color: #0f0e0e;
}
#services p {
  color: rgba(0, 0, 0, 0.75);
}
#services .service-desc {
  margin-bottom: 40px;
}