/* Header Section */

.intro {
  width: 100%;
  display: -webkit-box;
  height: 100%;
  padding: 0;
  background: url('../../../public/img/goldencyber-min.png') center center / cover no-repeat;
  opacity: 0.95;
  animation: waveEffect 20s infinite ease-in-out;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

@media (max-width: 480px) {
  .intro {
    background-size: cover;   /* Keep cover for full height */
    background-position: center center;  /* Center the image properly */
    background-attachment: scroll;  /* Avoid performance issues on mobile */
    height: 100vh;  /* Ensure full height on mobile screens */
    animation: waveEffect 20s infinite ease-in-out;
  }
}

@keyframes waveEffect {
  0% { background-position: 0% 0%; }
  50% { background-position: 50% 50%; }  /* Midpoint */
  100% { background-position: 50% 50%; }  /* Hold at midpoint */
}
@keyframes gradientMove {
  0% { background-position: 0% 50%; }
  100% { background-position: 100% 50%; }
}

.flip-bg {
  transform: scaleX(-1);
}
.intro .overlay {
  /* background: rgba(255, 255, 255, 0.2); */
}

.intro h1 {
  font-family: 'Raleway', sans-serif;
  color: transparent;
  font-size: 50px;
  font-weight: 700;
  text-transform: none;
  margin-top: 10%;
  margin-bottom: 10px;
  background: linear-gradient(30deg, #ffffff, #000000);
  background-clip: text;
  -webkit-background-clip: text;
  background-size: 400% 80%;
  animation: gradientAnimation 3s ease-in-out 1;
}

@media screen and (max-width: 768px) {
  .intro h1 {
    margin-top: 5%; /* Adjust this value as needed */
    background-size: 100% 30%;
    animation: gradientAnimation 3s ease-in-out 1;
  }
}

/* Keyframe animation for right-to-left gradient */
@keyframes gradientAnimation {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;  /* Ending the animation with the white part of the gradient */
    color: white /* Make the final state completely white */
  }
}

.intro h1 span {
  font-weight: 800;
  color: #5ca9fb;
}
.intro p {
  color: #fff;
  font-size: 30px;
  font-weight: 400;
  line-height: 30px;
  margin: 0 auto;
  margin-bottom: 60px;
}

header .intro-text {
  padding-top: 200px;
  padding-bottom: 10%;
  text-align: center;
}

/* Media Queries for Responsiveness */

/* For Tablets (Portrait) */
@media (max-width: 768px) {
  .intro h1 {
    font-size: 40px;  /* Smaller font for tablets */
  }

  .intro p {
    font-size: 18px;  /* Adjust paragraph font size */
    margin-bottom: 40px;  /* Reduced margin for smaller screens */
  }

  header .intro-text {
    padding-top: 150px;  /* Reduce padding */
    padding-bottom: 15%;
  }

  /* Remove border-radius for tablets */
  .intro {
    border-bottom-right-radius: 0;  /* Reset border-radius for smaller screens */
  }
}

/* For Mobile Devices (Portrait) */
@media (max-width: 480px) {
  .intro h1 {
    font-size: 30px;  /* Even smaller font for mobile */
    margin-bottom: 15px;  /* Reduced margin for mobile */
  }

  .intro p {
    font-size: 16px;  /* Further reduce font size */
    line-height: 24px;  /* Adjust line-height for readability */
    margin-bottom: 30px;  /* Reduce space below the paragraph */
  }

  header .intro-text {
    padding-top: 80%;  /* Further reduce padding for mobile */
    padding-bottom: 20%;
  }

  /* Remove border-radius for mobile */
  .intro {
    border-bottom-right-radius: 0;  /* Reset border-radius for mobile */
  }

  /* Flip Background for smaller screens */
  .flip-bg {
    transform: none;  /* Remove flip on smaller screens */
  }
}

/* For Tablets (Landscape) */
@media (max-width: 1024px) {
  .intro h1 {
    font-size: 45px;  /* Slightly smaller font for landscape tablets */
  }

  .intro p {
    font-size: 20px;  /* Adjust paragraph font size */
    margin-bottom: 50px;  /* Adjust bottom margin */
  }

  header .intro-text {
    padding-top: 180px;  /* Reduce padding for tablets */
    padding-bottom: 12%;
  }
}

/* For Small Screens or Very Large Mobile (Phones) */
@media (max-width: 375px) {
  .intro h1 {
    font-size: 25px;  /* Even smaller font size for small phones */
    margin-bottom: 10px;  /* Adjust margin */
  }

  .intro p {
    font-size: 14px;  /* Even smaller text for better readability */
    line-height: 22px;  /* Adjust line-height */
    margin-bottom: 25px;  /* Smaller bottom margin */
  }

  header .intro-text {
    padding-top: 100px;  /* Minimal top padding */
    padding-bottom: 25%;
  }

  /* Remove border-radius for small mobile screens */
  .intro {
    border-bottom-right-radius: 0;  /* Reset border-radius for small phones */
  }
}
